import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SplashScreenComponent } from "./pages/splash-screen/splash-screen.component";
import { BrowserModule } from "@angular/platform-browser";
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from "@angular/forms";
import { VerificationComponent } from "./pages/verification/verification.component";
import { ThreadComponent } from "./pages/thread/thread.component";
import { ParamResolverService } from "./common/services/param-resolver.service";
import { authRedirectGuard } from "./common/guards/auth-redirect.guard";
import { authorizationGuard } from "./common/guards/authorization.guard";

/**
 * Defines the routes for the application.
 */
const routes: Routes = [
	{
		path: 'invite',
		component: SplashScreenComponent,
	},
	{
		path: 'invite/:facilityName/:facilityId/:receiverId',
		component: SplashScreenComponent,
		resolve: {
			params: ParamResolverService
		}
	},
	{
		path: 'messages/:facilityName/:facilityId/:receiverId/:messageId',
		component: ThreadComponent,
		canActivate: [authRedirectGuard],
		resolve: {
			params: ParamResolverService
		}
	},
	{
		path: 'messages/:facilityName/:facilityId/:receiverId',
		component: ThreadComponent,
		canActivate: [authRedirectGuard],
		resolve: {
			params: ParamResolverService
		}
	},
	{ 
		path: "verification/:facilityName/:facilityId/:receiverId", 
		component: VerificationComponent, 
		canActivate: [authorizationGuard],
		resolve: {
			params: ParamResolverService
		}
	}
];

@NgModule({
	imports: [BrowserModule, RouterModule.forRoot(routes), CheckboxModule],
	exports: [RouterModule, CheckboxModule, FormsModule],
})
export class AppRoutingModule { }
