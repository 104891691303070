export const environment = {
	envVariables: {
		production: false,
		API_URL: "https://easeapplications-dev4.com",
		APP_ENV: "dev4",
		IS_DEBUG: true,
		MESSAGES_PULL_INTERVAL: 5000,
		DEFAULT_LOCALE: "en",
	},
};
